.root{
    composes: section-wrapper from global;
    display: flex;
    align-items: flex-end;
    margin-top: 110px;
}

.heading{
    flex: 1;
}

.title{
    composes : title from global;
}

.description{
    margin-bottom: 0;
    margin-top: 0.2rem;
    color : var(--theme-colors-grey-text)
}