.title{
    composes : title from global;
}

.footer{
    margin-top: 1rem;
    display: flex;
    justify-content: flex-end;
    flex: 1;
    align-items: flex-end;
    gap: 1rem;
}


.rootGreen td, .rootRed td{
    padding: 0.5rem 1rem;
    border-bottom: 2px solid var(--theme-colors-grey-border);
    background-color: white;
    border-radius: 5px;
}
.disabled{
    pointer-events: none;
}

.actions a{
    display: inline-block;
    margin-right: 1rem;
}

.actions a:last-of-type{
    margin-right: 0;
}

.actions,.date{
    white-space: nowrap;
}

.rootGreen:nth-child(odd), .rootRed:nth-child(odd){
    background: #fbfbfb;
}

.pictoDetails{
    width: 30px;
}

.actionsNull img{
    opacity: 0.5;
    
}

.containerStockItem{
    display: contents;
}

.rootGreen{
    
}

.rootRed td{
    background-color: #ff00000d;
}

.rootGreen td {
    background-color: #0080000f;
}

.rootRed td, .rootGreen td{
    border-radius: 0px;
    border-bottom: none;
}

.rootRed td, .rootGreen td{
    border-radius: 0px;
    border-bottom: none;
}

.containerIcon{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
.containerIcon svg{
    margin-left: 4px;
    fill: var(--theme-colors-yellow);
    stroke: white;
}