.root{
    display: flex;
    gap: 1rem;
}

.input{
    padding: 0.8rem 0.3rem ;
    font-size: 1rem;
    border:none;
    outline: none;
    min-width: 400px;
    border-bottom: 1px solid var(--theme-colors-grey-text);
}

.input:focus{
    border-bottom: 1px solid var(--theme-colors-main);
}