.root{
    background : var(--theme-colors-nav);
    position: sticky;


}

.wrapper{
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    height: 4rem;
    padding: 0 20px;


}
.logo{
    position:absolute;
    left: 20px;
    top: 0.6rem;
    height: 2.8rem;
}

.logo img{
    max-height:100%;
}

.clientLink{
    position: absolute;
    right: 20px;
    top: 0.6rem;
    height: 2.8rem;
    line-height: 2.8rem;
    text-decoration: none;
    composes : title from global;
    color: white;
    font-size: 1.2rem;
}

.clientLink:hover{
    color: var(--theme-colors-yellow);
}

div[class="containerNav"]{
    margin-top: 130px;
    background-color: var(--theme-colors-nav);
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    padding: 5px;
}

.logout {
    width: 48px;
    border-radius: 13px;
}

p[class="espaceClient"]{
    color: white;
    font-weight: bold;
    text-transform: uppercase;
    position: absolute;
    left: 140px;
    top: 0.5rem;
}

div[class="containerUser"] {
    color: white;
    font-weight: bold;
    text-transform: uppercase;
    position: absolute;
    right: 75px;
    top: 0.6rem;
    display: flex;
    border: solid 2px var(--theme-colors-yellow);
    border-radius: 5px;
    width: 250px;
    height: 44px;
    display: flex;
    align-items: center;
}

.user {
    width: 42px;
    height: 40px;
    margin-right: 15px;
}

p[class="userName"] {
    font-size: 16px;
    line-height: 1;
    margin: 0;
    font-weight: bold;
}

.nav_links{
    padding: 5px 0;
}

.nav_links a{
    margin: 0 10px;
}