.root{
    composes : title from global;
    color: white;
    font-size: 1.4rem;
    text-decoration: none;
    padding: 0 0.5rem;

}

.root:hover{
    color: white;
    text-decoration: underline ;
    text-decoration-color: var(--theme-colors-yellow);
}

.root_current{
    composes : root;
    text-decoration: underline;
    text-decoration-color: var(--theme-colors-yellow);
    color: var(--theme-colors-yellow);

}

.disabled{
    composes : root;
    cursor: not-allowed;
    color: rgb(241, 241, 241);
    pointer-events: none    ;
}

.disabled:hover{
    color: rgb(241, 241, 241);
    text-decoration: none;
}
