.root{
    margin: 100px 0;
}
.containerLog{
    max-width: 600px;
    margin: auto;
    display: block;
}
.insideCard{
    padding: 42px 24px; 
}
.containerInput{
    margin-bottom: 24px;
}
.label{
    margin-top: 16px;
}