.root{
    background: white;
    box-shadow:10px 10px 15px 0px rgba(0,0,0,0.4);
    width: 100%;
    padding: 1rem;
    border-radius: 0.5rem;
    position: relative;
    margin-top: 5px !important ;
    margin-bottom: 20px;
    min-height: 110px;
}
