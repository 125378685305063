.root{
    width:100%;
    outline: none;
    border: none;
    border-bottom : 1px solid var(--theme-colors-grey-text);
    padding: 0.7rem 1rem;
    padding-left: 0;
    font-size: 0.9rem;
}

.root:focus{
    border-bottom: 1px solid var(--theme-colors-main);
}