.root {
    display: grid;
    grid-template-columns: auto auto 1fr;
    gap: 2rem;
}

.card_root {
    margin: 1.4rem 0;
}

.bar_root {
    display: flex;
    justify-content: center;
    margin-left: 4rem;
}

.date {
    color: var(--theme-colors-darker-grey-text);
    display: flex;
    align-items: center;
}

.title {
    color: var(--theme-colors-nav);
    font-family: var(--theme-font-title);
}

.description {
    margin: 0;
    color: var(--theme-colors-grey-text);
    font-size: 0.8rem;
}

.badge_type {
    position: absolute;
    text-transform: uppercase;
    right: 0.8rem;
    top: 0.8rem;
    padding: 0.2rem 0.5rem;
    border-radius: 0.4rem;
    font-weight: bold;
}
.badge_type img{
    width: 30px;
}

.data_item {
    display: flex;
    flex-direction: column;
}
.data_item p{
    margin: 0;
    font-style: italic;
}
.data_item strong{
    margin-bottom: 6px;
}
.data_item_container {
    padding-left: 0;
}
.data_item_container {
    font-style: 1rem;
    list-style-type: none;
    margin: 0;
}
.bar {
    background: var(--theme-colors-main);
    width: 4px;
    height: 100%;
    position: relative;
}

.bar:after {
    position: absolute;
    width: 16px;
    height: 16px;
    background: white;
    border: 4px solid var(--theme-colors-main);
    border-radius: 50%;
    z-index: 10;
    top: 50%;
    left: 50%;
    transform: translate(-12px, -12px);
    display: block;
    content: "";
}

.bar_last {
    composes: bar;
    height: 50%;
}

.bar_last:after {
    top: unset;
    bottom: 0;
    transform: translate(-12px, 12px);
}
