.root{
    display: grid;
    grid-template-columns: repeat(2,1fr);
    gap: 20px;
    margin-top: 30px;
}
.container{
    display: grid;
}
.topContainer{
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    flex-flow: column wrap;
    height: 120px;
    font-weight: bold;
}
.topContainer p{
    margin: 0;
    padding: 0 10px ;
}

.chartContainer{
    display: grid;
    align-items: center;
    height: 100%;
}