.root{
    margin: 100px 0;
}
.containerLog{
    composes: page-container from global;
    margin-top: 2rem;    
    display: flex;
    flex-direction: column;
}

.containerHeader {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;
}

.inputTypeDate {
    max-width: 150px;
}
.buttonContainer{    
    display: flex;
    gap: 20px;
    margin: auto;
}

.date{
    display: flex;
    align-items: center;
    position: relative;
}
.date .dateTitle{
    background-color: var(--theme-colors-nav);    
    color: white;
    font-weight: 700;
    padding: 5px 10px;
    border-radius: 5px;
    z-index: 10;
    width: 115px;
    text-align: center;
}

.date .inputTypeDate{
    border-color: #a3a3a3;
    border-radius: 5px;
    padding: 3px 10px;
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
    left: 110px;
    position: absolute;
    box-shadow: 10px 10px 20px 0px rgb(163 163 163 / 80%);
    border-width: 1px;
    font-family: var(--theme-font);
}

h1{
    font-size: 1.4rem;
}