.root {
    composes: page-container from global;
}

.title{
    font-family: var(--theme-font-title);
    font-size: 1.4rem;
    text-transform: uppercase;
    font-weight: 700;
    display: flex;
    align-items: center;
    margin-bottom: 0;
}

.containerTable{
    border-top-right-radius: 0.5rem;
    border-top-left-radius: 0.5rem;
    overflow-x: scroll;
    width: 100%;
}

.tableCustomers{
    border-spacing: 0;
    width: 100%;
    border-collapse: collapse;
}
.tableCustomers thead{
    background: var(--theme-colors-nav);
    color: white;
}
.tableCustomers td{
    padding: 0 0.5rem 0 1rem;
    border-bottom: 2px solid var(--theme-colors-grey-border);
    background-color: white;
    border-radius: 5px;
}

.containerButtons{
    margin-top: 16px;
    margin-bottom: 16px;
}

.buttonBlue, .buttonRed, .buttonYellow{
    background-color: var(--theme-colors-nav);
    border: none;
    color: white;
    font-weight: 700;
    padding: 5px 20px;
    border-radius: 5px;
    margin-right: 10px;
    text-transform: uppercase;
    height: 30px;
    display: inline-flex;
    align-items: center;
    gap: 0.5rem;
    cursor: pointer;
}

.buttonRed{
    background-color: var(--theme-colors-red);
}
.buttonYellow{
    background-color: var(--theme-colors-yellow);
}
.buttonBlue:hover, .buttonRed:hover,.buttonYellow:hover,.searchTitle:hover {
    opacity: 0.8;
}

.modal{
    position: fixed;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 1;
    visibility: visible;
    transition: all 0.2s ease-in-out;
}

.modalClose{
    composes: modal;
    opacity: 0;
    visibility: hidden;
    transition: all 0.2s ease-in-out;
}

.backgroundModal{
    position: absolute;
    background-color: rgba(0,0,0,0.4);
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
}

.modalContainer{
    border-radius: 0.5rem;
    background-color: white;
    padding: 20px;
    width: 100%;
    max-width: 600px;
    position: relative;
    z-index: 2;
}
.contentModale h3{
    text-transform: uppercase;
    padding-bottom: 8px;
}
.contentModale p{
    font-size: 1.2rem;
}
.containerListInputs{
    margin-top: 16px;
}
.containerInput{
    margin-top: 16px;
}
.containerInput label{
    font-size: 1rem;
    font-weight: bold;
    display: block;
}
.containerButtonsModale{
    composes: containerButtons;
    margin-bottom: 0;
    display: flex;
    justify-content: flex-end;
}