.root{
    display: grid;
    grid-template-columns: repeat(2,1fr);
    gap: 20px;
    margin-top: 30px;
}
.container{
    display: grid;    
}
*{
    text-transform: uppercase;
}
h3{
    text-align: end;
}
h5{    
    margin-bottom: 5px;
}

.chartContainer{
    display: grid;
    align-items: center;
    height: 100%;
}

@media screen and (max-width:1300px) {
    .root{        
        grid-template-columns: 1fr;        
    }   
}