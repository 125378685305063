

:global(body){
  --theme-colors-main : #004A95;
  --theme-colors-nav: #001547;    
  --theme-colors-grey-border : #F5F5F5;
  --theme-colors-grey-text : #a3a3a3;
  --theme-colors-darker-grey-text: #84868A;
  --theme-colors-yellow : #ffc000;
  --theme-colors-red : #da0303;

  --theme-font-title : rift, sans-serif;
  --theme-font : mr-eaves-xl-modern,sans-serif;

  font-size: 16px;
  background: var(--theme-colors-grey-border);
  min-height: 100vh;
  padding-bottom: 2rem;
  font-family: var(--theme-font);
}

:global(.title){
  font-family: var(--theme-font-title);
  font-size: 2rem;
  text-transform: uppercase;
  font-weight: 700;
}

:global(.page-container){
  max-width: 1390px;
  width: 100%;
  margin: 100px auto;
  padding: 0 20px;

}

:global(.section-wrapper){
  margin-top: 2rem;
  box-shadow:5px 5px 15px 0px rgba(0,0,0,0.1);
  background: white;
  width: 100%;
  padding: 2rem;
  border-radius: 0.5rem;

}



:global(div),
:global(nav),
:global(section),
:global(header),
:global(footer),
:global(a),
:global(p),
:global(input){
  box-sizing: border-box;
}

:global(h1),
:global(h2),
:global(h3),
:global(h4),
:global(h5){
  margin: 0;
}