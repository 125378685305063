.root{
    padding: 2rem 0;
    text-align: center;
    font-size: 1rem;
}

.loader_root{
    display: flex;
    justify-content: center;
    padding-top: 1rem;
}

.label{
    font-family: var(--theme-font-title);
    font-weight: bold;
}