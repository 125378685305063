.root{
    background: var(--theme-colors-nav);
    color: white;

}

.root th{
    padding: 0.6rem 0.54rem;
    text-align: left;
    font-weight: bold;
    text-transform: uppercase;
}

.input{
    border-radius: 10px;
    height: 30px;
    width: 110px;
} 

.titleOrder{
    height: 30px;
    font-size: 15px;
    margin-bottom: 0;
}

.without_input{    
    height: 30px;
    font-size: 15px;
    margin-bottom: 30px;    
}

tr{
    height: 50px;
}