.root{
    background: var(--theme-colors-nav);
    color: white;

}

.root th{
    padding: 0.5rem ;
    text-align: left;
    font-weight: bold;
    text-transform: uppercase;

}

.input{
    border-radius: 10px;
    height: 30px;
    width: 100px;
}
.containerIcon{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.icon{
    font-size: 0.8rem;
    color: var(--theme-colors-yellow);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}