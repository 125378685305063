.root{
    min-width: 5.5rem;
    text-align: center;
    padding: 0.5rem 1rem;
    cursor: pointer;
    border-radius: 0.3rem;
}

.default{
    composes : root;
    background:var(--theme-colors-main);
    border: none;
    color: white;
    font-weight: bold;

}

.default:hover{
    opacity: 0.8;
}

.with_icon{
    display: inline-flex;
    align-items: center;
    gap: 0.5rem;
}

.iconBtn{
    composes:default;
    min-width: unset;
    padding: 0.5rem;
}

.disabled{
    composes:default;
    pointer-events: none;
    border: none;
    background: rgb(231, 231, 231);
    color: var(--theme-colors-grey-text);
}