.root {
    composes: page-container from global;
    margin-top: 2rem;
}

.title {
    font-family: var(--theme-font-title);
    color: var(--theme-colors-nav);
    flex: 1;
}

.heading {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
a {
    color: var(--theme-colors-nav);
}

a:hover {
    color: var(--theme-colors-yellow);
}

ul[class="orderSection_data_list__zlZe7"] {
    flex-direction: column;
    align-items: baseline;
}

ul[class="trackingItem_data_item_container__1qEYH"] {
    display: block;
}

span[class="titleYellow"]{
    color: var(--theme-colors-yellow);
}

p[class="right"]{
    font-family: var(--theme-font-title);
    font-size: 2.7rem;
    text-transform: uppercase;
    font-weight: 700;
    text-align: end;
    margin-top: 85px;
    margin-bottom: 0;
}

a[class="return"]{
    text-decoration: none;
    display: flex;
    align-items: center;
    font-weight: bold;
}

img[class="picto_return"]{
    width: 20px;
    height: 20px;
    margin-right: 10px;
}

.picto_doss_container {
    width: 40px;
    height: 40px;
    margin-left: 10px;
}
img[class="picto_doss"] {
    width: 40px;
    height: 40px;
}

p[class="left"]{
    font-family: var(--theme-font-title);
    font-size: 2rem;
    text-transform: uppercase;
    font-weight: 700;
    display: flex;
    align-items: center;
    margin-top: 15px;
}

span[class="titleYellowBis"] {
    color: var(--theme-colors-yellow);
    margin-right: 5px;
}

div[class="infos"]{
    width: 100%;
}

div[class="containerSection"]{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: 2%;
}

ul{
    width: 100%;
}

li{
    display: inline !important;
    line-height: 2;
}

.heading_open {
    background: var(--theme-colors-nav);
    box-shadow: 5px 5px 15px 0px rgb(0 0 0 / 10%);
    width: 100%;
    padding: 1rem;
    border-radius: 0.5rem;
    position: relative;
}

.title {
    font-family: var(--theme-font-title);
    color: white;
    flex: 1;
    line-height: 1;
}

.link{
    width: 100%;
}

a{
    text-decoration: none;
}
