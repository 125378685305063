.title{
    font-size: 1rem;
    font-family: var(--theme-font-title);
    color: var(--theme-colors-main);
}

.container{
    border-radius: 0.5rem;
    overflow: hidden;
    margin-top: 1rem;
    border: 1px solid var(--theme-colors-grey-border);
}

.table{
    width: 100%;
    border-spacing: 0;
    border-collapse: collapse; 
}
.table thead{
    background: var(--theme-colors-yellow);
    color: white;
}

.table td,.table th{
    padding:1rem;
    text-align: left;
}

.table tbody tr{
    border-bottom: 1px solid var(--theme-colors-grey-border);
}

th{
    text-transform: uppercase;
}

.root{
    padding: 0px 100px 20px 100px;
}

tbody tr{
    background: var(--theme-colors-grey-border) !important;
}

td, th{
    text-align: center !important;
}