.buttonActive{
    background-color: var(--theme-colors-yellow);
}

.buttonDisabled{
    background-color: #dfdfdf;
    color: var(--theme-colors-darker-grey-text);    
}

button{
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    font-weight: bold;
    cursor: pointer;
}