.address{
    color: var(--theme-colors-nav);
    padding-top: 0.5rem;
}

.address p{
    margin: 0;
    font-size: 0.9rem;
    line-height: 1.1;
}

.address_title{
    font-weight: bold;
    text-transform: uppercase;
}

.name{
    font-weight: bold;
    text-transform: uppercase;
}