.root {
    width: 100%;
}

/* .card_root {
    margin: 1.4rem 0 0;
} */

.containerStep {
    display: flex;
    flex-grow: 1;
    flex-basis: 0;
    flex-direction: column;
}

.bar_root {
    display: flex;
}

.bar_root_first {
    composes: bar_root;
    justify-content: flex-end;
}

.bar_root_first_change {
    composes: bar_root;
    justify-content: flex-end;
    background: var(--theme-colors-grey-border);
}

.bar {
    background: var(--theme-colors-yellow);
    width: 100%;
    height: 4px;
    position: relative;
}

.bar:after {
    position: absolute;
    width: 16px;
    height: 16px;
    background: var(--theme-colors-yellow);
    border: 4px solid var(--theme-colors-yellow);
    border-radius: 50%;
    z-index: 10;
    top: 50%;
    left: 50%;
    transform: translate(-12px, -12px);
    display: block;
    content: "";
}

.bar_first {
    composes: bar;
    width: 50%;
}

.bar_first_change {
    composes: bar;
    width: 50%;
    background-color: var(--theme-colors-grey-border);
}

.bar_first:after {
    left: 0;
}

.bar_first_change:after {
    left: 0;
    border: 4px solid var(--theme-colors-grey-border);
    background: var(--theme-colors-grey-border);
}

.bar_last {
    composes: bar;
    width: 50%;
}

.bar_last_change {
    composes: bar;
    width: 50%;
    background: var(--theme-colors-grey-border);
}

.bar_last:after {
    left: 100%;
    width: 75px;
    height: 75px;
    transform: translate(-37px, -37px);
}

.bar_last_change:after {
    left: 100%;
    width: 75px;
    height: 75px;
    transform: translate(-37px, -37px);
    background-color: #eee !important;
    border-color: grey;
}

.imageStep {
    position: absolute;
    left: 100%;
    top: 0;
    z-index: 11;
    width: 50px;
    height: 50px;
    transform: translate(-20px, -20px);
}

.imageStepChange {
    position: absolute;
    left: 100%;
    top: 0;
    z-index: 11;
    width: 50px;
    height: 50px;
    transform: translate(-20px, -20px);
    filter: invert(50%);
}

.description {
    text-align: center;
    margin-top: 50px;
    font-size: 16px;
    color: var(--theme-colors-grey-text);
}
.description_last_step {
    composes: description;
    color: var(--theme-colors-yellow);
    font-size: 20px;
    font-weight: bold;
}
