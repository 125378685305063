.border{
    border: 2px solid white;
    border-radius: 5px ;
    padding: 10px;
    display: grid;
    grid-template-columns: repeat(2,1fr);    
    gap: 10px;
    width: 80%;
    font-weight: bold;
}
.border p{
    margin: 0;    
}
.mapLegend{
    display: grid;
    grid-template-columns: repeat(3,1fr);    
    padding-left: 30px;    
}
.mapLegend p {
    display: flex; 
    align-items: center;
    gap: 5px;
}
.mapLegend p{
    margin:10px 0;
}
.svgContainer{    
    padding-top: 20px; 
    display: flex;
    justify-content: center;     
}

.dep {
    cursor: pointer;
}

.tooltip{
    background: white;
    padding: 8px 16px;
    border: 2px solid black;
    text-transform: uppercase;    
}

.tooltipText{
    font-weight: bold;
}

.icon{
    border-radius: 100%;    
    min-width: 20px;
    min-height: 20px;
    display: inline-block;
}

.detailContainer {
    display: flex;
    align-items: center;
    margin-top: 10px;
}

.falseChart{
    height: auto !important;
}